export const NEW = "new"

export const EXPERTS = "/"

export const EXPERT_ID = "expertId"
export interface ExpertParams {
    [EXPERT_ID]: string
}
export const EXPERT = (expertId: string | number) => `/expert/${expertId}`

export const TOKEN = "token"
export interface MeParams {
    [TOKEN]: string
}
export const ME = (token: string) => `/me/${token}`

export const PROJECT_ID = "projectId"
export interface ProjectParams {
    [PROJECT_ID]: string
}
export const PROJECT = (projectId: string | number) => `/project/${projectId}`
