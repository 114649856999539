import React, { useState } from "react"
import { Button, Dropdown, Menu } from "antd"
import { DownOutlined, PlusOutlined } from "@ant-design/icons"
import { useApi } from "../api/ApiClientProvider"
import useSWR, { mutate } from "swr"
import {
    AssignmentStatus,
    Project,
    ProjectExpertAssignment,
    ProjectExpertAssignmentRequest,
    ProjectRequest,
} from "../api/models"
import { Modal } from "./Modal"
import { ProjectForm } from "./ProjectForm"

export interface ProjectAssignmentDropdownProps {
    expertId: number
}

export const ProjectAssignmentDropdown: React.FC<ProjectAssignmentDropdownProps> = ({
    expertId,
}) => {
    const api = useApi()

    const [showProjectModal, setShowProjectModal] = useState(false)

    const { data, mutate: mutateAssignableProjects } = useSWR<Project[]>(
        `/experts/${expertId}/assignable-projects`,
        url => api.get(url, { hideGlobalSpinner: true }),
        { revalidateOnMount: false },
    )

    const addToProject = (projectId: number) => {
        return api.post<ProjectExpertAssignment, ProjectExpertAssignmentRequest>(
            "/project_expert_assignments",
            {
                expert_id: expertId,
                project_id: projectId,
                status: AssignmentStatus.Backlog,
            },
        )
    }

    const createProject = (projectRequest: ProjectRequest) => {
        return api.post<Project, ProjectRequest>("/projects", projectRequest)
    }

    const onAddToProject = async (projectId: number) => {
        await mutateAssignableProjects(async projects => {
            await addToProject(projectId)
            return projects?.filter(project => project.id !== projectId)
        }, false)
        await mutate(`/project_events?expert_id=${expertId}`)
    }

    const onVisibleChange = async (visible: boolean) => {
        if (visible) {
            // fetch once the dropdown becomes visible
            await mutateAssignableProjects()
        }
    }

    const onSubmitProjectForm = async (projectRequest: ProjectRequest) => {
        const project = await createProject(projectRequest)

        setShowProjectModal(false)

        const appendProject = (projects?: Project[]) => {
            return projects ? [...projects, project] : [project]
        }
        await mutate("/projects", appendProject)
        await mutateAssignableProjects(appendProject, false)

        await onAddToProject(project.id)
    }

    const dropdownList = (
        <Menu>
            <Menu.Item key="new project" onClick={() => setShowProjectModal(true)}>
                <PlusOutlined />
                New project
            </Menu.Item>
            {data?.map(project => (
                <Menu.Item key={project.id} onClick={() => onAddToProject(project.id)}>
                    {project.name}
                </Menu.Item>
            ))}
        </Menu>
    )

    return (
        <>
            <Dropdown trigger={["click"]} onVisibleChange={onVisibleChange} overlay={dropdownList}>
                <span>
                    <Button style={{ paddingLeft: 0, border: 0 }} type="link">
                        Add to project <DownOutlined />
                    </Button>
                </span>
            </Dropdown>
            <Modal
                visible={showProjectModal}
                footer={null}
                onCancel={() => setShowProjectModal(false)}
            >
                <ProjectForm onSubmit={onSubmitProjectForm} submitLabel="Create and assign" />
            </Modal>
        </>
    )
}
