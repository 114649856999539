import { KeycloakService } from "../auth/keycloak"
import { notification } from "antd"
import { EventEmitter, EventHandler } from "../eventEmitter"

const VALIDATION_ERROR_CODE = 422

export interface Options {
    hideErrorNotification?: boolean
    hideGlobalSpinner?: boolean
}

export class ApiError extends Error {
    constructor(public statusCode: number, public body: any) {
        super("Api error")
    }
}

export class ApiClient {
    private requestCount = 0
    private readonly requestCountEmitter = new EventEmitter<number>()

    constructor(private apiUrl: string, private keycloak: KeycloakService) {}

    getRequestCount() {
        return this.requestCount
    }

    onRequestCountChange(handler: EventHandler<number>) {
        return this.requestCountEmitter.on(handler)
    }

    get = async <Res>(relativeUrl: string, options: Options = {}): Promise<Res> =>
        this.withOptions(options, async () => {
            const response = await this.fetch(relativeUrl)
            return await response.json()
        })

    post = async <Res, Body>(
        relativeUrl: string,
        body: Body,
        options: Options = {},
    ): Promise<Res> =>
        this.withOptions(options, async () => {
            const response = await this.fetch(relativeUrl, {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    "Content-Type": "application/json",
                },
            })
            return await response.json()
        })

    put = async <T, Body>(relativeUrl: string, body: Body, options: Options = {}): Promise<T> =>
        this.withOptions(options, async () => {
            const response = await this.fetch(relativeUrl, {
                method: "PUT",
                body: JSON.stringify(body),
                headers: {
                    "Content-Type": "application/json",
                },
            })
            return await response.json()
        })

    delete = async (relativeUrl: string, options: Options = {}): Promise<void> =>
        this.withOptions(options, async () => {
            await this.fetch(relativeUrl, {
                method: "DELETE",
            })
        })

    private async fetch(relativeUrl: string, requestInit: RequestInit = {}): Promise<Response> {
        const url = new URL(relativeUrl, this.apiUrl)

        if (this.keycloak.isLoggedIn()) {
            const token = await this.keycloak.getToken()
            this.setHeader(requestInit, "Authorization", `Bearer ${token}`)
        }

        const response = await fetch(url.href, requestInit)

        if (!response.ok) {
            throw new ApiError(response.status, await response.json())
        }

        return response
    }

    private async withOptions<T>(options: Options, fetcher: () => Promise<T>) {
        if (!options.hideGlobalSpinner) {
            this.requestCount++
            this.requestCountEmitter.emit(this.requestCount)
        }
        try {
            return await fetcher()
        } catch (error) {
            if (!options.hideErrorNotification) {
                this.notifyFetchError(error)
            }
            throw error
        } finally {
            if (!options.hideGlobalSpinner) {
                this.requestCount--
                this.requestCountEmitter.emit(this.requestCount)
            }
        }
    }

    private setHeader(requestInit: RequestInit, name: string, value: string) {
        if (!requestInit.headers) {
            requestInit.headers = {}
        }

        if (requestInit.headers instanceof Headers) {
            requestInit.headers.append(name, value)
        } else if (Array.isArray(requestInit.headers)) {
            requestInit.headers.push([name, value])
        } else {
            requestInit.headers[name] = value
        }
    }

    private notifyFetchError(error: unknown) {
        if (error instanceof ApiError) {
            if (error.statusCode === VALIDATION_ERROR_CODE) {
                notification.error({
                    message: "Validation error",
                    description: "One or more input fields are invalid.",
                })
            } else if (typeof error.body?.detail === "string") {
                notification.error({
                    message: "Error",
                    description: error.body.detail,
                })
            } else {
                notification.error({
                    message: "Unknown API error",
                })
            }
        } else {
            notification.error({
                message: "Network error",
            })
        }
    }
}
