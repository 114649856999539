export type Optional<T> = T | null

export interface WithId {
    id: number
}

interface ExpertBase {
    title: Tag
    first_name: string
    last_name: string

    organisation: string
    type_of_organisation: Tag
    position: string

    email: string
    linkedin?: Optional<string>
    phone?: Optional<string>

    industries: Tag[]
    skills: Tag[]
    formats: Tag[]

    interests?: Optional<string>
    other?: Optional<string>

    allow_student_contact: boolean
    allow_alumni_contact: boolean
    allow_staff_contact: boolean
}

export interface Expert extends ExpertBase, WithId {
    archived: boolean
}

export interface ExpertRequest extends ExpertBase {}

interface AccessTokenBase {
    expert_id?: Optional<string>
}

export interface AccessToken extends AccessTokenBase, WithId {
    token: string
}

export interface AccessTokenRequest extends AccessTokenBase {}

export interface TagBase {
    name: string
}

export interface RootTag extends TagBase, WithId {
    parent_id: null
    root_id: null
}

export interface Tag extends TagBase, WithId {
    parent_id: number
    root_id: number
}

export interface TagRequest extends TagBase {
    parent_id: number
}

export interface ProjectBase {
    name: string
    project_type: Tag
}

export interface Project extends ProjectBase, WithId {
    archived: boolean
}

export interface ProjectRequest extends ProjectBase {}

export enum AssignmentStatus {
    Backlog = "Backlog",
    Contacted = "Contacted",
    Responded = "Responded",
    NotResponded = "NotResponded",
}

export interface ProjectExpertAssignmentBase {
    status: AssignmentStatus
    project_id: number
    expert_id: number
    comments?: Optional<string>
}

export interface ProjectExpertAssignment extends ProjectExpertAssignmentBase, WithId {}

export interface ProjectExpertAssignmentRequest extends ProjectExpertAssignmentBase {
    rating?: number
}

export interface ProjectEventBase {
    project: Project
    user_id: string
    expert_id: number
    timestamp: string
}

export interface ProjectAddEvent extends ProjectEventBase, WithId {
    event_type: "Add"
    new_status: AssignmentStatus
}

export interface ProjectMoveEvent extends ProjectEventBase, WithId {
    event_type: "Move"
    new_status: AssignmentStatus
}

export interface ProjectRemoveEvent extends ProjectEventBase, WithId {
    event_type: "Remove"
}

export type ProjectEvent = ProjectAddEvent | ProjectMoveEvent | ProjectRemoveEvent

export interface User {
    id: string
    username: string
    firstName?: Optional<string>
    lastName?: Optional<string>
}

export interface ProjectMemberBase {
    project_id: number
    user_id: string
}

export interface ProjectMemberRequest extends ProjectMemberBase {}

export interface ProjectMember extends ProjectMemberBase {
    id: number
    owner: boolean
}
