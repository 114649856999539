import React, { PropsWithChildren, useEffect, useState } from "react"
import { Route } from "react-router-dom"
import { RouteProps } from "react-router"
import { useKeycloak, useLoggedIn } from "./AuthProvider"

export type AuthenticatedRouteProps = Omit<RouteProps, "render" | "component">

export function AuthenticatedRoute({
    children,
    ...props
}: PropsWithChildren<AuthenticatedRouteProps>) {
    const keycloak = useKeycloak()
    const loggedIn = useLoggedIn()

    useEffect(() => {
        if (!loggedIn) {
            keycloak.init()
        }
    }, [])

    return <Route {...props}>{loggedIn && children}</Route>
}
