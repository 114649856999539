import React, { createContext, useContext, useEffect, useState } from "react"
import { ApiClient } from "./client"

const ApiContext = createContext<ApiClient | undefined>(undefined)

export interface ApiProviderProps {
    api: ApiClient
}

export const ApiClientProvider: React.FC<ApiProviderProps> = ({ api, ...props }) => {
    return <ApiContext.Provider value={api} {...props} />
}

export function useApi() {
    const api = useContext(ApiContext)
    if (!api) {
        throw new Error("Missing ApiProvider.")
    }
    return api
}

export function useLoading() {
    const api = useApi()
    const [requestCount, setRequestCount] = useState(api.getRequestCount())

    useEffect(() => api.onRequestCountChange(setRequestCount), [])

    return requestCount > 0
}
