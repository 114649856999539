import React, { ReactNode, useCallback, useEffect, useState } from "react"
import { Input, Popover } from "antd"

export interface PopoverInputProps {
    value: string
    onChange: (value: string) => void
    icon: ReactNode
}

export const PopoverInput: React.FC<PopoverInputProps> = ({ children, value, onChange, icon }) => {
    const [inputValue, setInputValue] = useState(value)

    const [visible, setVisible] = useState(false)

    // set the inputValue whenever the external value changes
    useEffect(() => {
        setInputValue(value)
    }, [value])

    // useCallback to keep the same function reference over all renders
    const handleEsc = useCallback((event: KeyboardEvent) => {
        if (event.key === "Escape") {
            setVisible(false)
        }
    }, [])

    // register esc handler when the popover is opened and remove it again when it is closed
    useEffect(() => {
        if (visible) {
            document.addEventListener("keydown", handleEsc)
            return () => document.removeEventListener("keydown", handleEsc)
        }
    }, [visible])

    const onSubmit = useCallback(() => {
        onChange(inputValue)
        setVisible(false)
    }, [inputValue])

    return (
        <Popover
            destroyTooltipOnHide
            trigger="click"
            visible={visible}
            onVisibleChange={setVisible}
            content={
                <Input.Search
                    autoFocus
                    value={inputValue}
                    onChange={event => setInputValue(event.target.value)}
                    onPressEnter={onSubmit}
                    onSearch={onSubmit}
                    enterButton={icon}
                />
            }
        >
            {children}
        </Popover>
    )
}
