import { TagEditor, TagEditorProps } from "./TagEditor"
import React, { useState } from "react"
import { Button } from "antd"
import { Modal } from "./Modal"

export const TagEditorModal: React.FC<TagEditorProps> = props => {
    const [visible, setVisible] = useState(false)

    return (
        <>
            <Button
                style={{ paddingLeft: 0, border: 0 }}
                type="link"
                onClick={() => setVisible(true)}
            >
                Manage tags
            </Button>
            <Modal visible={visible} footer={null} onCancel={() => setVisible(false)}>
                <TagEditor {...props} />
            </Modal>
        </>
    )
}
