import React, { useCallback, useMemo, useState } from "react"
import { groupTagsById, renderTags, TagIds } from "../tags"
import { Tag, TagRequest } from "../api/models"
import { TreeSelect } from "antd"
import { useApi } from "../api/ApiClientProvider"
import useSWR from "swr"
import { toMap } from "../util"
import { useParams } from "react-router-dom"
import { MeParams } from "../routes"

export interface TagSelectProps {
    value?: Tag[]
    onChange?: (value: Tag[]) => void
    rootTagId: TagIds
    multiple?: boolean
}

export const TagSelect: React.FC<TagSelectProps> = ({
    rootTagId,
    value,
    onChange,
    multiple = true,
}) => {
    const { token } = useParams<MeParams>()
    const getTagsUrl = token
        ? `/tags_for_token/${token}?root=${rootTagId}`
        : `/tags?root=${rootTagId}`
    const postTagUrl = token ? `/tags_for_token/${token}` : `/tags`

    const api = useApi()
    const { data, mutate } = useSWR<Tag[]>(getTagsUrl, url =>
        api.get(url, { hideGlobalSpinner: true }),
    )

    const tagsById = useMemo(() => groupTagsById(data ?? []), [data])

    const unwrappedValue = useMemo(
        () => value?.map(withId => withId.id).filter(id => tagsById.has(id)),
        [value, tagsById],
    )

    const wrappedOnChange = useCallback(
        (ids: number[]) => {
            onChange?.call(
                null,
                ids.map(id => tagsById.get(id)).filter((tag): tag is Tag => tag !== undefined),
            )
        },
        [onChange, tagsById],
    )

    const treeData = useMemo(() => renderTags(rootTagId, data ?? [], tag => tag.name), [
        data,
        rootTagId,
    ])

    const tagsByName = useMemo(() => toMap(data ?? [], tag => tag.name.toLowerCase()), [data])
    const [searchValue, setSearchValue] = useState("")
    const onEnter = async () => {
        let tag = tagsByName.get(searchValue.toLowerCase())
        if (!tag) {
            // tag doesn't exist yet. Create new tag.
            const newTag = await api.post<Tag, TagRequest>(postTagUrl, {
                name: searchValue,
                parent_id: rootTagId,
            })
            await mutate(tags => [...(tags ?? []), newTag], false)
            tag = newTag // need assign as an extra statement so TS can infer the correct type
        }
        onChange?.([...(value ?? []), tag])
        setSearchValue("")
    }

    return (
        <TreeSelect
            style={{ width: "100%" }}
            showSearch
            multiple={multiple}
            treeDefaultExpandAll
            treeNodeFilterProp="title"
            treeData={treeData}
            value={multiple ? unwrappedValue ?? [] : unwrappedValue?.[0]}
            onChange={(value: number[] | number | undefined) =>
                multiple
                    ? wrappedOnChange(value as number[])
                    : wrappedOnChange(value === undefined ? [] : [value as number])
            }
            searchValue={searchValue}
            onSearch={setSearchValue}
            onKeyDown={async event => {
                if (event.key === "Enter") {
                    event.preventDefault() // prevent form from being submitted
                    onEnter()
                }
            }}
        />
    )
}
