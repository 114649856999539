import React, { useMemo } from "react"
import useSWR from "swr"
import { Tag, TagRequest } from "../api/models"
import { useApi } from "../api/ApiClientProvider"
import { Button, Popconfirm, Tree } from "antd"
import { PopoverInput } from "./PopoverInput"
import { DeleteOutlined, PlusCircleOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons"
import { groupTagsById, renderTags, TagIds } from "../tags"

export interface TagEditorProps {
    rootTagId: TagIds
}

export const TagEditor: React.FC<TagEditorProps> = ({ rootTagId }) => {
    const api = useApi()
    const { data, mutate } = useSWR<Tag[]>(`/tags?root=${rootTagId}`, api.get)

    const create = (tag: TagRequest) =>
        mutate(async tags => {
            const newTag = await api.post<Tag, TagRequest>(`/tags`, tag)
            return tags ? [...tags, newTag] : tags
        }, false)

    const save = (tag: Tag) =>
        mutate(async tags => {
            const updatedTag = await api.put<Tag, TagRequest>(`/tags/${tag.id}`, tag)
            const updatedTags = tags?.filter(otherTag => otherTag.id !== updatedTag.id)
            updatedTags?.push(updatedTag)
            return updatedTags
        }, false)

    const delete_ = async (tag: Tag) => {
        await api.delete(`/tags/${tag.id}`)
        await mutate() // revalidate as removing all children of the tag is prone to errors
    }

    const renderTag = (tag: Tag) => (
        <>
            <PopoverInput
                icon={<SaveOutlined />}
                value={tag.name}
                onChange={name => save({ ...tag, name })}
            >
                {tag.name}
            </PopoverInput>
            &nbsp;
            <PopoverInput
                icon={<PlusOutlined />}
                value=""
                onChange={name => create({ name, parent_id: tag.id })}
            >
                <PlusCircleOutlined />
            </PopoverInput>
            &nbsp;
            <Popconfirm
                title="Do you want to delete this tag and all its subordinate tags?"
                onConfirm={() => delete_(tag)}
            >
                <DeleteOutlined />
            </Popconfirm>
        </>
    )

    const treeData = useMemo(() => renderTags(rootTagId, data ?? [], renderTag), [data, rootTagId])

    const tagsById = useMemo(() => groupTagsById(data || []), [data])

    return (
        <>
            <h2>
                Manage Tags &nbsp;
                <PopoverInput
                    icon={<PlusOutlined />}
                    value=""
                    onChange={name => create({ name, parent_id: rootTagId })}
                >
                    <Button type="primary">
                        <PlusOutlined />
                    </Button>
                </PopoverInput>
            </h2>

            <Tree
                defaultExpandAll
                draggable
                treeData={treeData}
                onDrop={({ node, dragNode, dropToGap }) => {
                    const tag = tagsById.get(node.key as number)
                    const dropTag = tagsById.get(dragNode.key as number)
                    if (tag && dropTag) {
                        save({
                            ...dropTag,
                            parent_id: dropToGap ? tag.parent_id : tag.id,
                        })
                    }
                }}
            />
        </>
    )
}
