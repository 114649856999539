import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { useApi } from "../api/ApiClientProvider"
import { MeParams } from "../routes"
import { ExpertForm } from "../components/ExpertForm"
import { Expert, ExpertRequest } from "../api/models"
import useSWR from "swr"

import logo from "../assets/logo_black_with_text.png"
import classes from "./MeView.scss"
import { Button, Modal } from "antd"
import { DeleteOutlined } from "@ant-design/icons"

export const MeView: React.FC = () => {
    const { token } = useParams<MeParams>()

    const api = useApi()

    const { data, mutate } = useSWR<Expert>(`/expert_by_token/${token}`, api.get)

    const [hasSaved, setHasSaved] = useState(false)

    const save = async (expert: ExpertRequest) => {
        await mutate(() => api.put(`/expert_by_token/${token}`, expert), false)
        setHasSaved(true)
    }

    const delete_ = async () => {
        await api.delete(`/expert_by_token/${token}`)
        Modal.info({ content: "Your data has been deleted." })
    }

    return (
        <div className={classes.background}>
            <div className={classes.content}>
                <img src={logo} alt="CDTM logo" />
                <h1 className={classes.title}>
                    “Great you are here. Let’s drive innovation together!”
                </h1>
                <p>
                    At CDTM, we believe in cross-organisational exchange to find promising ideas and
                    build relevant solutions! Therefore, we invite you to become part of our
                    network. What industry-specific or subject-matter expertise could potential
                    founders and innovators learn from you? What are you looking for when engaging
                    with our community?
                </p>
                <p>
                    This database fosters decentralized networking. Depending on your preference,
                    our students, management team and alumni will directly contact you for coachings
                    and lectures among other formats. Thank you for signing up. We look forward to
                    connect and engage with you!
                </p>
                <p>
                    <br />
                    Best regards,
                    <br />
                    Your CDTM Management Team
                </p>
                <p>
                    PS Any questions or additional ideas for collaboration? Feel free to reach out
                    via info@cdtm.de.
                </p>
                <ExpertForm expert={data} onSubmit={save} submitLabel="Save" />
                {hasSaved && (
                    <>
                        <p>
                            <i>
                                Thank you for signing up! You can amend or delete your data by
                                accessing this <a href={location.href}>LINK</a> anytime.
                            </i>
                        </p>
                        <p>
                            <i>We look forward to great discussions and initiatives with you!</i>
                        </p>
                    </>
                )}
                {data && (
                    <Button danger onClick={delete_}>
                        <DeleteOutlined /> Delete my data
                    </Button>
                )}
            </div>
        </div>
    )
}
