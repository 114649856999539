import Keycloak from "keycloak-js"
import { EventEmitter } from "../eventEmitter"

export class KeycloakService {
    private readonly keycloak
    private refreshPromise: Promise<unknown> = Promise.resolve()
    private loggedInEmitter = new EventEmitter<boolean>()
    private rolesEmitter = new EventEmitter<Set<string>>()

    onLoggedInChange = this.loggedInEmitter.on.bind(this.loggedInEmitter)
    onRolesChange = this.rolesEmitter.on.bind(this.rolesEmitter)

    constructor(url: string, realm: string, clientId: string) {
        this.keycloak = Keycloak({ url, realm, clientId })
        this.keycloak.onTokenExpired = this.tokenExpired.bind(this)
    }

    async init(): Promise<void> {
        const loggedIn = await this.keycloak.init({
            onLoad: "login-required",
            checkLoginIframe: false,
        })
        this.loggedInEmitter.emit(loggedIn)
        this.rolesEmitter.emit(this.getRoles())
    }

    async getToken(): Promise<string> {
        if (this.keycloak.isTokenExpired(30)) {
            this.tokenExpired()
        }

        await this.refreshPromise
        return this.keycloak.token!
    }

    isLoggedIn() {
        return !!this.keycloak.authenticated
    }

    getRoles() {
        return new Set(this.keycloak.tokenParsed?.realm_access?.roles)
    }

    logout() {
        this.keycloak.logout()
    }

    getAccountUrl() {
        return this.keycloak.createAccountUrl()
    }

    private tokenExpired(): void {
        this.refreshPromise = this.refreshToken()
    }

    private async refreshToken() {
        await this.keycloak.updateToken(5)
        this.rolesEmitter.emit(this.getRoles())
    }
}
