import { ProjectRequest } from "../api/models"
import React, { useEffect } from "react"
import { useForm } from "antd/lib/form/Form"
import { Button, Form, Input } from "antd"
import { TagEditorModal } from "./TagEditorModal"
import { SingleTagSelect } from "./SingleTagSelect"
import { TagIds } from "../tags"
import { useHasRole } from "../auth/AuthProvider"
import { Roles } from "../auth/roles"

export interface ProjectFormProps {
    project?: ProjectRequest
    onSubmit: (project: ProjectRequest) => void
    submitLabel: string
}

export const ProjectForm: React.FC<ProjectFormProps> = ({ project, onSubmit, submitLabel }) => {
    const [form] = useForm<ProjectRequest>()
    const isStaff = useHasRole(Roles.Staff)

    useEffect(() => {
        // update form fields whenever the value changes
        form.resetFields()
    }, [project])

    return (
        <Form
            layout="vertical"
            name="project"
            form={form}
            initialValues={project}
            onFinish={onSubmit}
        >
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item
                name="project_type"
                label={
                    <>
                        Project Type
                        {isStaff && (
                            <>
                                &nbsp;-&nbsp;
                                <TagEditorModal rootTagId={TagIds.ProjectType} />
                            </>
                        )}
                    </>
                }
                rules={[{ required: true }]}
            >
                <SingleTagSelect rootTagId={TagIds.ProjectType} />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    {submitLabel}
                </Button>
            </Form.Item>
        </Form>
    )
}
