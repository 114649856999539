import React, { createContext, useContext, useEffect, useState } from "react"
import { KeycloakService } from "./keycloak"

const AuthContext = createContext<KeycloakService | undefined>(undefined)

export interface AuthProviderProps {
    keycloak: KeycloakService
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ keycloak, ...props }) => {
    return <AuthContext.Provider value={keycloak} {...props} />
}

export function useKeycloak() {
    const keycloak = useContext(AuthContext)
    if (!keycloak) {
        throw new Error("Missing AuthProvider.")
    }
    return keycloak
}

export function useLoggedIn() {
    const keycloak = useKeycloak()
    const [loggedIn, setLoggedIn] = useState(keycloak.isLoggedIn())

    useEffect(() => keycloak.onLoggedInChange(setLoggedIn), [])

    return loggedIn
}

export function useRoles() {
    const keycloak = useKeycloak()
    const [roles, setRoles] = useState(keycloak.getRoles())

    useEffect(() => keycloak.onRolesChange(setRoles), [])

    return roles
}

export function useHasRole(...roles: string[]) {
    const currentRoles = useRoles()
    return roles.some(role => currentRoles.has(role))
}
