import { Tag } from "../api/models"
import React from "react"
import { Tag as TagComponent } from "antd"

export interface TagListProps {
    tags?: Tag[]
}

export const TagList: React.FC<TagListProps> = ({ tags }) => {
    return (
        <>
            {(tags ?? []).map(({ id, name }) => (
                <TagComponent key={id}>{name}</TagComponent>
            ))}
        </>
    )
}
