import { RootTag, Tag } from "./api/models"
import { DataNode } from "antd/lib/tree"
import { ReactNode } from "react"

export enum TagIds {
    Industries = -100,
    Skills = -200,
    Title = -300,
    TypeOfOrganisation = -400,
    Formats = -500,
    ProjectType = -600,
}

export function groupTagsById(tags: Array<Tag | RootTag>) {
    const tagsById = new Map<number, Tag>()

    for (const tag of tags) {
        if (tag.parent_id !== null) {
            tagsById.set(tag.id, tag)
        }
    }

    return tagsById
}

export function groupTagsByParentId(tags: Array<Tag>) {
    const tagsByParentId = new Map<number, Tag[]>()

    for (const tag of tags) {
        if (!tagsByParentId.has(tag.parent_id)) {
            tagsByParentId.set(tag.parent_id, [])
        }
        tagsByParentId.get(tag.parent_id)?.push(tag)
    }

    return tagsByParentId
}

export function renderTags(rootTagId: TagIds, tags: Tag[], renderTitle: (tag: Tag) => ReactNode) {
    const tagsByParentId = groupTagsByParentId(tags)

    const renderTag = (tag: Tag): DataNode => ({
        key: tag.id,
        children: tagsByParentId.get(tag.id)?.map(renderTag),
        title: renderTitle(tag),
    })

    return tagsByParentId.get(rootTagId)?.map(renderTag) ?? []
}
