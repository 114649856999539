import React, { DragEvent, useEffect, useMemo, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { EXPERT, EXPERTS, ProjectParams } from "../routes"
import { useApi } from "../api/ApiClientProvider"
import useSWR, { mutate } from "swr"
import { Button, Card, Col, Input, Rate, Row, Switch, Tag } from "antd"
import {
    AssignmentStatus,
    Expert,
    Project,
    ProjectExpertAssignment,
    ProjectExpertAssignmentRequest,
    ProjectRequest,
} from "../api/models"

import classes from "./ProjectView.scss"
import { toMap, useDebounce } from "../util"
import { ProjectForm } from "../components/ProjectForm"
import { Modal } from "../components/Modal"
import { DeleteOutlined } from "@ant-design/icons"
import { TagList } from "../components/TagList"
import { ProjectMembersModal } from "../components/ProjectMembersModal"

interface AssignmentCardProps {
    onDelete: () => void
    onChange: (assignment: ProjectExpertAssignmentRequest) => void
    assignment: ProjectExpertAssignment
    expert: Expert
}

const AssignmentCard: React.FC<AssignmentCardProps> = ({
    onChange,
    onDelete,
    expert,
    assignment,
}) => {
    const history = useHistory()

    const tags = useMemo(() => [...expert.industries, ...expert.skills], [expert])

    const [currentComments, setCurrentComments] = useState(assignment.comments)
    const debouncedComments = useDebounce(currentComments, 1000)
    useEffect(() => {
        if (debouncedComments !== assignment.comments) {
            onChange({ ...assignment, comments: debouncedComments })
        }
    }, [debouncedComments, assignment.comments])

    return (
        <Card
            onClick={() => history.push(EXPERT(expert.id))}
            actions={[
                <DeleteOutlined
                    onClick={event => {
                        event.stopPropagation()
                        onDelete()
                    }}
                />,
            ]}
        >
            <p>
                {expert.title.name} {expert.first_name} {expert.last_name}
            </p>
            <p>{expert.organisation}</p>
            <p>{expert.email}</p>
            <p>
                <TagList tags={tags} />
            </p>
            <Input.TextArea
                rows={3}
                value={currentComments ?? ""}
                onChange={event => setCurrentComments(event.target.value)}
                onClick={event => event.stopPropagation()}
            />
        </Card>
    )
}

interface ColumnsProps {
    assignments: ProjectExpertAssignment[]
    experts: Map<number, Expert>
    status: AssignmentStatus
    onChange: (assignmentId: number, assignment: ProjectExpertAssignmentRequest) => void
    onDragStart: (assignment: ProjectExpertAssignment) => void
    onDrop: () => void
    onDelete: (assignment: ProjectExpertAssignment) => void
}

const Column: React.FC<ColumnsProps> = props => {
    const assignments = useMemo(
        () => props.assignments.filter(assignment => assignment.status === props.status),
        [props.assignments],
    )

    const onDragStart = (assignment: ProjectExpertAssignment, event: DragEvent) => {
        event.dataTransfer.effectAllowed = "move"
        props.onDragStart(assignment)
    }

    const onDragOver = (event: DragEvent) => {
        event.preventDefault()
        event.dataTransfer.dropEffect = "move"
    }

    const onDrop = (event: DragEvent) => {
        event.preventDefault()
        props.onDrop()
    }

    return (
        <Col span={6} onDragOver={onDragOver} onDrop={onDrop}>
            <div className={classes.column}>
                {assignments.map(assignment => {
                    const expert = props.experts.get(assignment.expert_id)
                    if (!expert) return null
                    return (
                        <div
                            key={assignment.id}
                            draggable="true"
                            onDrag={event => onDragStart(assignment, event)}
                        >
                            <AssignmentCard
                                assignment={assignment}
                                expert={expert}
                                onChange={changedAssignment =>
                                    props.onChange(assignment.id, changedAssignment)
                                }
                                onDelete={() => props.onDelete(assignment)}
                            />
                        </div>
                    )
                })}
            </div>
        </Col>
    )
}

export const ProjectView: React.FC = () => {
    const { projectId } = useParams<ProjectParams>()
    const api = useApi()
    const history = useHistory()
    const [showProjectModal, setShowProjectModal] = useState(false)
    const [showRatingModal, setShowRatingModal] = useState(false)

    const { data: project, mutate: mutateProject } = useSWR<Project>(
        `/projects/${projectId}`,
        api.get,
    )

    const { data: assignments, mutate: mutateAssignments } = useSWR<ProjectExpertAssignment[]>(
        `/project_expert_assignments?project_id=${projectId}`,
        api.get,
    )

    const { data: experts } = useSWR<Expert[]>(`/experts?project_id=${projectId}`, api.get)

    const saveProject = (project: ProjectRequest) =>
        mutateProject(async () => {
            return await api.put<Project, ProjectRequest>(`/projects/${projectId}`, project)
        }, false)

    const deleteProject = async () => {
        await api.delete(`/projects/${projectId}`)
        history.push(EXPERTS)
        await mutate("/projects", (projects: Project[]) =>
            projects.filter(project => project.id !== parseInt(projectId)),
        )
    }

    const saveAssignment = (assignmentId: number, assignment: ProjectExpertAssignmentRequest) =>
        mutateAssignments(async assignments => {
            const result = await api.put<ProjectExpertAssignment, ProjectExpertAssignmentRequest>(
                `/project_expert_assignments/${assignmentId}`,
                assignment,
            )
            const removed = assignments?.filter(other => other.id !== assignmentId)
            return removed ? [...removed, result] : [result]
        }, false)

    const deleteAssignment = (assignment: ProjectExpertAssignment) =>
        mutateAssignments(async assignments => {
            await api.delete(`/project_expert_assignments/${assignment.id}`)
            return assignments?.filter(other => other.id !== assignment.id)
        }, false)

    const onSubmitProjectForm = async (project: ProjectRequest) => {
        await saveProject(project)
        setShowProjectModal(false)
    }

    const expertsById = useMemo(() => toMap(experts ?? [], expert => expert.id), [experts])

    const [dragged, setDragged] = useState<ProjectExpertAssignment>()
    const [rating, setRating] = useState<number>()

    const onDrop = (status: AssignmentStatus) => {
        if (dragged && status !== dragged.status) {
            if (status === AssignmentStatus.Responded) {
                setRating(5)
                setShowRatingModal(true)
            } else {
                saveAssignment(dragged.id, { ...dragged, status })
            }
        }
    }

    const onRating = async () => {
        if (dragged) {
            await saveAssignment(dragged.id, {
                ...dragged,
                status: AssignmentStatus.Responded,
                rating,
            })
            setShowRatingModal(false)
        }
    }

    const renderColumn = (status: AssignmentStatus) => (
        <Column
            status={status}
            assignments={assignments ?? []}
            experts={expertsById}
            onChange={saveAssignment}
            onDragStart={setDragged}
            onDrop={() => onDrop(status)}
            onDelete={deleteAssignment}
        />
    )

    return (
        <>
            <div className={classes.container}>
                <div className={classes.titleRow}>
                    <h2 className={classes.header}>
                        {project?.name}
                        &nbsp;
                        <Tag>{project?.project_type.name}</Tag>
                    </h2>

                    <div className={classes.actions}>
                        <Switch
                            unCheckedChildren="Edit"
                            checkedChildren="Edit"
                            checked={showProjectModal}
                            onChange={setShowProjectModal}
                        />
                        <ProjectMembersModal projectId={parseInt(projectId)} />
                        <Button danger onClick={deleteProject}>
                            <DeleteOutlined />
                        </Button>
                    </div>
                </div>
                <Row>
                    <Col span={6}>
                        <h3>Backlog</h3>
                    </Col>
                    <Col span={6}>
                        <h3>Contacted</h3>
                    </Col>
                    <Col span={6}>
                        <h3>Responded</h3>
                    </Col>
                    <Col span={6}>
                        <h3>Not Responded</h3>
                    </Col>
                </Row>
                <Row className={classes.lastRow}>
                    {renderColumn(AssignmentStatus.Backlog)}
                    {renderColumn(AssignmentStatus.Contacted)}
                    {renderColumn(AssignmentStatus.Responded)}
                    {renderColumn(AssignmentStatus.NotResponded)}
                </Row>
            </div>
            <Modal
                visible={showProjectModal}
                footer={null}
                onCancel={() => setShowProjectModal(false)}
            >
                <ProjectForm project={project} onSubmit={onSubmitProjectForm} submitLabel="Save" />
            </Modal>
            <Modal
                visible={showRatingModal}
                okText="Save"
                onOk={onRating}
                onCancel={() => setShowRatingModal(false)}
            >
                <h2>How do you rate this interaction?</h2>
                <Rate value={rating} onChange={setRating} />
            </Modal>
        </>
    )
}
