import { useEffect, useState } from "react"
import { User } from "./api/models"

export function useDebounce<T>(value: T, delay: number): T {
    const [debouncedValue, setDebouncedValue] = useState(value)

    useEffect(() => {
        const timeoutId = setTimeout(() => setDebouncedValue(value), delay)
        return () => clearTimeout(timeoutId)
    }, [value, delay])

    return debouncedValue
}

export function toMap<T, Key>(items: T[], keyGetter: (item: T) => Key): Map<Key, T> {
    const itemsById = new Map<Key, T>()

    for (const item of items) {
        const key = keyGetter(item)
        itemsById.set(key, item)
    }

    return itemsById
}

export type ValuesOf<T> = T[keyof T]

export type KeysOfType<Obj, Type> = ValuesOf<
    { [Key in keyof Obj]-?: Obj[Key] extends Type ? Key : never }
>

export function getUserLabel(user?: User) {
    if (user?.firstName && user?.lastName) {
        return `${user.firstName} ${user.lastName}`
    } else if (user?.username) {
        return user.username
    } else {
        return "<Deleted>"
    }
}
