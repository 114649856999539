import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { ME, TOKEN } from "../routes"
import { AuthenticatedRoute } from "../auth/AuthenticatedRoute"
import { MeView } from "./MeView"
import { RequestSpinner } from "../components/RequestSpinner"
import { Dashboard } from "./Dashboard"

export const App: React.FC = () => {
    return (
        <RequestSpinner>
            <Router>
                <Switch>
                    <Route exact path={ME(`:${TOKEN}`)}>
                        <MeView />
                    </Route>
                    <AuthenticatedRoute path="/">
                        <Dashboard />
                    </AuthenticatedRoute>
                </Switch>
            </Router>
        </RequestSpinner>
    )
}
