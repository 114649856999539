import React, { StrictMode } from "react"
import { render } from "react-dom"
import { App } from "./views/App"
import { KeycloakService } from "./auth/keycloak"
import { AuthProvider } from "./auth/AuthProvider"
import { ApiClient, ApiError } from "./api/client"
import { ApiClientProvider } from "./api/ApiClientProvider"
import { SWRConfig } from "swr"

const keycloak = new KeycloakService(APP_KC_URL, APP_KC_REALM, APP_KC_APP_ID)
const api = new ApiClient(APP_API_URL, keycloak)

render(
    <StrictMode>
        <AuthProvider keycloak={keycloak}>
            <ApiClientProvider api={api}>
                <SWRConfig
                    value={{
                        revalidateOnFocus: false,
                        revalidateOnReconnect: false,
                        onErrorRetry: (error: unknown, key, config, revalidate) => {
                            if (
                                error instanceof ApiError &&
                                400 <= error.statusCode &&
                                error.statusCode < 500
                            ) {
                                return
                            }
                            setTimeout(() => revalidate({}), 5000)
                        },
                    }}
                >
                    <App />
                </SWRConfig>
            </ApiClientProvider>
        </AuthProvider>
    </StrictMode>,
    document.getElementById("root"),
)
