import React, { useMemo, useState } from "react"
import { Button, List, Select, Space } from "antd"
import { DeleteOutlined, TeamOutlined } from "@ant-design/icons"
import useSWR from "swr"
import { ProjectMember, ProjectMemberRequest, User } from "../api/models"
import { useApi } from "../api/ApiClientProvider"
import { Modal } from "./Modal"
import { LabeledValue } from "antd/lib/select"
import { getUserLabel, toMap } from "../util"

interface SelectOption extends LabeledValue {
    user: User
}

export interface ProjectMembersModalProps {
    projectId: number
}

export const ProjectMembersModal: React.FC<ProjectMembersModalProps> = ({ projectId }) => {
    const [visible, setVisible] = useState(false)

    const api = useApi()

    const { data: users } = useSWR<Record<string, User>>("/users", api.get)
    const { data: members, mutate: mutateMembers } = useSWR<ProjectMember[]>(
        `/project_members?project_id=${projectId}`,
        api.get,
    )

    const addProjectMember = (user: User) =>
        mutateMembers(async members => {
            const newMember = await api.post<ProjectMember, ProjectMemberRequest>(
                "/project_members",
                {
                    project_id: projectId,
                    user_id: user.id,
                },
            )
            return members ? [...members, newMember] : [newMember]
        }, false)

    const deleteProjectMember = (member: ProjectMember) =>
        mutateMembers(async members => {
            await api.delete(`/project_members/${member.id}`)
            return members?.filter(otherMember => otherMember.id !== member.id)
        }, false)

    const [selected, setSelected] = useState<string>()
    const onSelect = async (value: string, option: SelectOption) => {
        // we need to set and reset the value for the select to stay empty
        setSelected(value)
        try {
            await addProjectMember(option.user)
        } finally {
            // we need to set and reset the value for the select to stay empty
            setSelected(undefined)
        }
    }

    const selectOptions = useMemo(() => {
        if (!users || !members) {
            return []
        }
        const membersByUserId = toMap(members, member => member.user_id)
        return Object.values(users)
            .filter(user => !membersByUserId.has(user.id))
            .map(user => ({
                key: user.id,
                value: getUserLabel(user),
                user,
            }))
            .sort((a, b) => {
                return a.value.localeCompare(b.value)
            })
    }, [users, members])

    return (
        <>
            <Button onClick={() => setVisible(true)}>
                <TeamOutlined />
            </Button>
            <Modal visible={visible} footer={null} onCancel={() => setVisible(false)}>
                <Space direction="vertical" style={{ width: "100%" }}>
                    <h2>Project Members</h2>
                    <Select
                        style={{ width: "100%" }}
                        showSearch
                        placeholder="Add member"
                        // antd typing seems to be broken -> cast to any
                        value={selected as any}
                        onChange={onSelect as any}
                        options={selectOptions}
                    />
                    <List
                        bordered
                        dataSource={members}
                        renderItem={member => (
                            <List.Item
                                key={member.id}
                                style={{ display: "flex", justifyContent: "space-between" }}
                            >
                                {getUserLabel(users?.[member.user_id])}
                                <Button
                                    danger
                                    disabled={member.owner}
                                    onClick={() => deleteProjectMember(member)}
                                >
                                    <DeleteOutlined />
                                </Button>
                            </List.Item>
                        )}
                    />
                </Space>
            </Modal>
        </>
    )
}
