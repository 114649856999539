import { Layout, Menu, Modal } from "antd"
import {
    HddOutlined,
    LinkOutlined,
    LogoutOutlined,
    PlusOutlined,
    ProjectOutlined,
    UserOutlined,
} from "@ant-design/icons"
import React, { useState } from "react"
import { EXPERT, EXPERT_ID, EXPERTS, ME, NEW, PROJECT, PROJECT_ID } from "../routes"
import { ExpertsView } from "./ExpertsView"
import { CreateExpertView } from "./CreateExpertView"
import { EditExpertView } from "./EditExpertView"
import { Route, Switch, useHistory } from "react-router-dom"
import { useApi } from "../api/ApiClientProvider"
import { useHasRole, useKeycloak } from "../auth/AuthProvider"
import { Roles } from "../auth/roles"
import useSWR from "swr"
import { Project } from "../api/models"
import { ProjectView } from "./ProjectView"
import logo from "../assets/logo_white.png"
import classes from "./Dashboard.scss"

const { Content, Sider } = Layout

export const Dashboard: React.FC = () => {
    const api = useApi()
    const history = useHistory()
    const keycloak = useKeycloak()

    const isStaff = useHasRole(Roles.Staff)

    const [collapsed, setCollapsed] = useState(false)

    const copySignUpLink = async () => {
        const { token } = await api.post("/access_tokens", {})
        const url = new URL(ME(token), document.baseURI)
        Modal.info({
            title: "Created new sign up link",
            content: <a href={url.href}>{url.href}</a>,
        })
    }

    const { data: projects } = useSWR<Project[]>("/projects", api.get)

    return (
        <Layout style={{ minHeight: "100vh" }}>
            <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed}>
                <Menu theme="dark" mode="inline" selectable={false}>
                    <img className={classes.logo} src={logo} alt="CDTM logo" />
                    <Menu.Item icon={<HddOutlined />} onClick={() => history.push(EXPERTS)}>
                        Overview
                    </Menu.Item>
                    {isStaff && (
                        <Menu.Item
                            icon={<PlusOutlined />}
                            onClick={() => history.push(EXPERT(NEW))}
                        >
                            Create Expert
                        </Menu.Item>
                    )}
                    {isStaff && navigator.clipboard && (
                        <Menu.Item icon={<LinkOutlined />} onClick={copySignUpLink}>
                            Create Sign Up Link
                        </Menu.Item>
                    )}
                    <Menu.SubMenu icon={<ProjectOutlined />} title="Projects">
                        {projects?.map(project => (
                            <Menu.Item
                                key={project.id}
                                onClick={() => history.push(PROJECT(project.id))}
                            >
                                {project.name}
                            </Menu.Item>
                        ))}
                    </Menu.SubMenu>
                    <Menu.Item
                        icon={<UserOutlined />}
                        onClick={() => window.open(keycloak.getAccountUrl())}
                    >
                        Account
                    </Menu.Item>
                    <Menu.Item icon={<LogoutOutlined />} onClick={() => keycloak.logout()}>
                        Logout
                    </Menu.Item>
                </Menu>
            </Sider>
            <Layout className={classes.content}>
                <Content>
                    <Switch>
                        <Route exact path={EXPERTS}>
                            <ExpertsView />
                        </Route>
                        <Route exact path={EXPERT(NEW)}>
                            <CreateExpertView />
                        </Route>
                        <Route exact path={EXPERT(`:${EXPERT_ID}`)}>
                            <EditExpertView />
                        </Route>
                        <Route exact path={PROJECT(`:${PROJECT_ID}`)}>
                            <ProjectView />
                        </Route>
                    </Switch>
                </Content>
            </Layout>
        </Layout>
    )
}
