export type EventHandler<Event> = (event: Event) => void

export class EventEmitter<Event> {
    private handlers = new Set<EventHandler<Event>>()

    on(handler: EventHandler<Event>) {
        this.handlers.add(handler)
        return () => {
            this.handlers.delete(handler)
        }
    }

    emit(event: Event) {
        for (const handler of this.handlers) {
            handler(event)
        }
    }
}
