import { Tag } from "../api/models"
import { TagIds } from "../tags"
import React, { useCallback, useMemo } from "react"
import { TagSelect } from "./TagSelect"

export interface SingleTagSelectProps {
    value?: Tag | undefined
    onChange?: (value: Tag | undefined) => void
    rootTagId: TagIds
}

export const SingleTagSelect: React.FC<SingleTagSelectProps> = ({ rootTagId, value, onChange }) => {
    const wrappedValue = useMemo(() => (value ? [value] : []), [value])
    const wrappedOnChange = useCallback(
        (tags: Tag[]) => onChange?.(tags.length ? tags[0] : undefined),
        [onChange],
    )
    return (
        <TagSelect
            value={wrappedValue}
            onChange={wrappedOnChange}
            rootTagId={rootTagId}
            multiple={false}
        />
    )
}
