import React from "react"

import { useHistory } from "react-router-dom"
import { useApi } from "../api/ApiClientProvider"
import { Expert, ExpertRequest } from "../api/models"
import { ExpertForm } from "../components/ExpertForm"
import { mutate } from "swr"
import { EXPERT } from "../routes"

export const CreateExpertView: React.FC = () => {
    const history = useHistory()
    const api = useApi()

    const save = async (expert: ExpertRequest) => {
        const result = await api.post<Expert, ExpertRequest>("/experts", expert)
        mutate(`/experts/${result.id}`, result)
        history.replace(EXPERT(result.id))
    }

    return (
        <>
            <ExpertForm onSubmit={save} submitLabel="Create" />
        </>
    )
}
