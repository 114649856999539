import React, { useState } from "react"
import { Input, Table } from "antd"
import { SearchOutlined } from "@ant-design/icons"

import { useApi } from "../api/ApiClientProvider"
import { Expert, Tag } from "../api/models"
import { useHistory } from "react-router-dom"
import { EXPERT } from "../routes"
import { useDebounce } from "../util"
import useSWR from "swr"
import { TagSelect } from "../components/TagSelect"
import { TagIds } from "../tags"
import { TagList } from "../components/TagList"
import { useHasRole } from "../auth/AuthProvider"
import { Roles } from "../auth/roles"
import { ProjectAssignmentDropdown } from "../components/ProjectAssignmentDropdown"

interface SearchParams {
    name?: string
    company?: string
    email?: string
    fields: Tag[]
    methods: Tag[]
}

function constructUrl({ name, company, email, fields, methods }: SearchParams) {
    const queryParams = [
        ...(name ? [`name=${encodeURIComponent(name)}`] : []),
        ...(company ? [`company=${encodeURIComponent(company)}`] : []),
        ...(email ? [`email=${encodeURIComponent(email)}`] : []),
        ...fields.map(({ id }) => `field=${id}`),
        ...methods.map(({ id }) => `method=${id}`),
    ]

    if (queryParams.length > 0) {
        return `/experts?${queryParams.join("&")}`
    } else {
        return "/experts"
    }
}

function if_<T>(cond: boolean, value: T): T[] {
    return cond ? [value] : []
}

export const ExpertsView: React.FC = () => {
    const history = useHistory()
    const api = useApi()

    const isStaff = useHasRole(Roles.Staff)

    const [searchParams, setSearchParams] = useState<SearchParams>({
        fields: [],
        methods: [],
    })

    const debouncedSearchParams = useDebounce(searchParams, 500)

    const { data } = useSWR<Expert[]>(constructUrl(debouncedSearchParams), api.get)

    return (
        <Table
            dataSource={data}
            rowKey="id"
            onRow={expert => ({
                onClick() {
                    history.push(EXPERT(expert.id))
                },
            })}
            columns={[
                {
                    title: "Last Name",
                    dataIndex: "last_name",
                    sorter: true,
                    filterIcon: (
                        <SearchOutlined
                            style={{ color: searchParams.name ? "#1890ff" : undefined }}
                        />
                    ),
                    filterDropdown: (
                        <Input
                            style={{ width: "300px" }}
                            value={searchParams.name}
                            onChange={e =>
                                setSearchParams({ ...searchParams, name: e.target.value })
                            }
                        />
                    ),
                },
                {
                    title: "Organisation",
                    dataIndex: "organisation",
                    sorter: true,
                    filterIcon: (
                        <SearchOutlined
                            style={{ color: searchParams.name ? "#1890ff" : undefined }}
                        />
                    ),
                    filterDropdown: (
                        <Input
                            style={{ width: "300px" }}
                            value={searchParams.company}
                            onChange={e =>
                                setSearchParams({ ...searchParams, company: e.target.value })
                            }
                        />
                    ),
                },
                {
                    title: "Industries",
                    key: "industries",
                    render: (expert: Expert) => <TagList tags={expert.industries} />,
                    filterIcon: (
                        <SearchOutlined
                            style={{
                                color: searchParams.fields.length ? "#1890ff" : undefined,
                            }}
                        />
                    ),
                    filterDropdown: (
                        <div style={{ width: "300px" }}>
                            <TagSelect
                                rootTagId={TagIds.Industries}
                                value={searchParams.fields}
                                onChange={value =>
                                    setSearchParams({ ...searchParams, fields: value })
                                }
                            />
                        </div>
                    ),
                },
                {
                    title: "Skills",
                    key: "skills",
                    render: (expert: Expert) => <TagList tags={expert.skills} />,
                    filterIcon: (
                        <SearchOutlined
                            style={{
                                color: searchParams.methods.length ? "#1890ff" : undefined,
                            }}
                        />
                    ),
                    filterDropdown: (
                        <div style={{ width: "300px" }}>
                            <TagSelect
                                rootTagId={TagIds.Skills}
                                value={searchParams.methods}
                                onChange={value =>
                                    setSearchParams({ ...searchParams, methods: value })
                                }
                            />
                        </div>
                    ),
                },
                ...if_(isStaff, {
                    title: "Actions",
                    key: "action",
                    render: (expert: Expert) => (
                        <div onClick={event => event.stopPropagation()}>
                            <ProjectAssignmentDropdown expertId={expert.id} />
                        </div>
                    ),
                }),
            ]}
        />
    )
}
