import React, { useEffect, useState } from "react"
import { Button, Checkbox, Form, Input, InputProps } from "antd"
import { useForm } from "antd/lib/form/Form"
import { ExpertRequest, Tag } from "../api/models"
import { TagSelect } from "./TagSelect"
import { TagIds } from "../tags"
import { TagEditorModal } from "./TagEditorModal"
import { useHasRole, useLoggedIn } from "../auth/AuthProvider"
import { TagList } from "./TagList"
import { SingleTagSelect } from "./SingleTagSelect"
import { KeysOfType } from "../util"
import { Roles } from "../auth/roles"

export interface ExpertFormProps {
    expert?: ExpertRequest
    onSubmit: (expert: ExpertRequest) => void
    submitLabel: string
    readonly?: boolean
}

const EMPTY_EXPERT: Partial<ExpertRequest> = {
    industries: [],
    skills: [],
    formats: [],
    allow_alumni_contact: true,
    allow_student_contact: true,
    allow_staff_contact: true,
}

export const ExpertForm: React.FC<ExpertFormProps> = ({
    expert,
    onSubmit,
    submitLabel,
    readonly,
}) => {
    const isStaff = useHasRole(Roles.Staff)
    const [form] = useForm<ExpertRequest>()

    useEffect(() => {
        // update form fields whenever the value changes
        form.resetFields()
    }, [expert, readonly])

    const renderSingleTagSelect = (
        field: KeysOfType<ExpertRequest, Tag>,
        label: string,
        rootTagId: TagIds,
    ) => (
        <Form.Item
            name={readonly ? undefined : field}
            label={
                <>
                    {label}
                    {isStaff && !readonly && (
                        <>
                            &nbsp;-&nbsp;
                            <TagEditorModal rootTagId={rootTagId} />
                        </>
                    )}
                </>
            }
            rules={[{ required: !readonly }]}
        >
            {readonly ? <b>{expert?.[field]?.name}</b> : <SingleTagSelect rootTagId={rootTagId} />}
        </Form.Item>
    )

    const renderInput = (
        field: KeysOfType<ExpertRequest, string | null | undefined>,
        label: string,
        type: InputProps["type"],
        required: boolean,
    ) => (
        <Form.Item
            name={readonly ? undefined : field}
            label={label}
            rules={[{ required: !readonly && required }]}
        >
            {readonly ? <b>{expert?.[field]}</b> : <Input type={type} />}
        </Form.Item>
    )

    const renderTagSelect = (
        field: KeysOfType<ExpertRequest, Tag[]>,
        label: string,
        rootTagId: TagIds,
        required: boolean,
    ) => (
        <Form.Item
            name={readonly ? undefined : field}
            rules={[{ required: !readonly && required }, { type: "array", max: 5 }]}
            label={
                <>
                    {label}
                    {isStaff && !readonly && (
                        <>
                            &nbsp;-&nbsp;
                            <TagEditorModal rootTagId={rootTagId} />
                        </>
                    )}
                </>
            }
        >
            {readonly ? <TagList tags={expert?.[field]} /> : <TagSelect rootTagId={rootTagId} />}
        </Form.Item>
    )

    const renderTextArea = (
        field: KeysOfType<ExpertRequest, string | null | undefined>,
        label: string,
        required: boolean,
    ) => (
        <Form.Item
            name={readonly ? undefined : field}
            label={label}
            rules={[{ required: !readonly && required }, { type: "string", max: 2000 }]}
        >
            {readonly ? <b>{expert?.[field]}</b> : <Input.TextArea rows={4} />}
        </Form.Item>
    )

    const [allowContactAll, setAllowContactAll] = useState(false)

    useEffect(() => {
        if (
            !expert ||
            (expert?.allow_staff_contact &&
                expert?.allow_student_contact &&
                expert?.allow_alumni_contact)
        ) {
            setAllowContactAll(true)
        }
    }, [expert, readonly])

    const onAllowContactAllChange = (value: boolean) => {
        setAllowContactAll(value)
        if (value) {
            form.setFieldsValue({
                allow_staff_contact: true,
                allow_student_contact: true,
                allow_alumni_contact: true,
            })
        }
    }

    return (
        <Form
            layout="vertical"
            name="expert"
            form={form}
            initialValues={expert ?? EMPTY_EXPERT}
            onFinish={onSubmit}
        >
            {renderSingleTagSelect("title", "Title", TagIds.Title)}
            {renderInput("first_name", "First Name", "text", true)}
            {renderInput("last_name", "Last Name", "text", true)}

            {renderInput("organisation", "Organisation", "text", true)}
            {renderSingleTagSelect(
                "type_of_organisation",
                "Type of Organisation",
                TagIds.TypeOfOrganisation,
            )}
            {renderInput("position", "Position / Title", "text", true)}

            {renderInput("email", "Contact details Email", "email", true)}
            {renderInput("linkedin", "Contact details LinkedIn", "text", false)}
            {renderInput("phone", "Contact details Phone", "tel", false)}

            {renderTagSelect(
                "industries",
                "Industries you consider yourself an expert in",
                TagIds.Industries,
                false,
            )}
            {renderTagSelect(
                "skills",
                "Methods and skills you consider yourself an expert in",
                TagIds.Skills,
                false,
            )}
            {renderTagSelect(
                "formats",
                "I am interested in following formats",
                TagIds.Formats,
                true,
            )}

            {renderTextArea("interests", "Engaging with CDTM I am looking for", false)}

            <Form.Item label="I am available to be contacted by">
                <Form.Item noStyle>
                    <Checkbox
                        disabled={readonly}
                        checked={allowContactAll}
                        onChange={event => onAllowContactAllChange(event.target.checked)}
                    >
                        Entire CDTM Community
                    </Checkbox>
                </Form.Item>
                <Form.Item name="allow_staff_contact" valuePropName="checked" noStyle>
                    <Checkbox disabled={readonly || allowContactAll}>Management Team</Checkbox>
                </Form.Item>
                <Form.Item name="allow_student_contact" valuePropName="checked" noStyle>
                    <Checkbox disabled={readonly || allowContactAll}>Students</Checkbox>
                </Form.Item>
                <Form.Item name="allow_alumni_contact" valuePropName="checked" noStyle>
                    <Checkbox disabled={readonly || allowContactAll}>Alumni</Checkbox>
                </Form.Item>
            </Form.Item>

            {renderTextArea("other", "What else should we know about you?", false)}

            {!readonly && (
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {submitLabel}
                    </Button>
                </Form.Item>
            )}
        </Form>
    )
}
