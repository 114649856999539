import React, { useState } from "react"

import { useHistory, useParams } from "react-router-dom"
import { ExpertParams, EXPERTS } from "../routes"
import { useApi } from "../api/ApiClientProvider"
import { Expert, ExpertRequest, ProjectEvent, User } from "../api/models"
import { ExpertForm } from "../components/ExpertForm"
import useSWR from "swr"
import { Button, Collapse, List, Switch } from "antd"
import { useHasRole } from "../auth/AuthProvider"
import { Roles } from "../auth/roles"
import { DeleteOutlined } from "@ant-design/icons"

import classes from "./EditExpertView.scss"
import { ProjectAssignmentDropdown } from "../components/ProjectAssignmentDropdown"
import { getUserLabel } from "../util"

function projectEventToMessage(event: ProjectEvent, users?: Record<string, User>) {
    switch (event.event_type) {
        case "Add":
            return `Added to project "${event.project.name}" in status "${
                event.new_status
            }" by ${getUserLabel(users?.[event.user_id])}.`
        case "Move":
            return `Moved to status "${event.new_status}" in project "${
                event.project.name
            }" by ${getUserLabel(users?.[event.user_id])}.`
        case "Remove":
            return `Removed from project "${event.project.name}" by ${event.user_id}.`
    }
}

interface ProjectEventLogDetailsProps {
    expertId: number | string
    projectId: number | string
    users?: Record<string, User>
}

const ProjectEventLogDetails: React.FC<ProjectEventLogDetailsProps> = ({
    expertId,
    projectId,
    users,
}) => {
    const api = useApi()

    const { data } = useSWR<ProjectEvent[]>(
        `/project_events?expert_id=${expertId}&project_id=${projectId}`,
        api.get,
    )

    return (
        <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(event: ProjectEvent) => (
                <List.Item key={event.id}>{projectEventToMessage(event, users)}</List.Item>
            )}
        />
    )
}

interface ProjectEventLogProps {
    expertId: number | string
}

const ProjectEventLog: React.FC<ProjectEventLogProps> = ({ expertId }) => {
    const api = useApi()

    const { data: events } = useSWR<ProjectEvent[]>(
        `/project_events?expert_id=${expertId}`,
        api.get,
    )
    const { data: users } = useSWR<Record<string, User>>("/users", api.get)

    return (
        <Collapse accordion>
            {events?.map((event: ProjectEvent) => (
                <Collapse.Panel key={event.id} header={projectEventToMessage(event, users)}>
                    <ProjectEventLogDetails
                        expertId={expertId}
                        projectId={event.project.id}
                        users={users}
                    />
                </Collapse.Panel>
            ))}
        </Collapse>
    )
}

export const EditExpertView: React.FC = () => {
    const { expertId } = useParams<ExpertParams>()

    const isStaff = useHasRole(Roles.Staff)

    const api = useApi()
    const history = useHistory()

    const { data, mutate } = useSWR<Expert>(`/experts/${expertId}`, api.get)

    const save = (expert: ExpertRequest) =>
        mutate(() => api.put(`/experts/${expertId}`, expert), false)

    const deleteExpert = async () => {
        await api.delete(`/experts/${expertId}`)
        history.replace(EXPERTS)
    }

    const [readonly, setReadonly] = useState(true)

    return (
        <>
            <div className={classes.titleRow}>
                <h2>
                    {data?.first_name} {data?.last_name}
                    &nbsp;
                    <ProjectAssignmentDropdown expertId={parseInt(expertId)} />
                </h2>
                <div className={classes.actions}>
                    {isStaff && !data?.archived && (
                        <Switch
                            unCheckedChildren="Edit"
                            checkedChildren="Edit"
                            checked={!readonly}
                            onChange={checked => setReadonly(!checked)}
                        />
                    )}
                    {isStaff && !data?.archived && (
                        <Button danger onClick={deleteExpert}>
                            <DeleteOutlined />
                        </Button>
                    )}
                </div>
            </div>
            <ExpertForm
                readonly={readonly || !isStaff}
                expert={data}
                onSubmit={save}
                submitLabel="Save"
            />
            {isStaff && <ProjectEventLog expertId={expertId} />}
            <br />
        </>
    )
}
