import { ModalProps } from "antd/lib/modal"
import { Modal as OriginalModal } from "antd"
import React from "react"
import { RequestSpinner } from "./RequestSpinner"

export const Modal: React.FC<ModalProps> = ({ children, ...props }) => {
    return (
        <OriginalModal {...props}>
            <RequestSpinner>{children}</RequestSpinner>
        </OriginalModal>
    )
}
